import styled from 'styled-components';

export const HeaderSection = styled.header`
  position: absolute;
  width: 100%;
  height: 100px;
  padding: 40px 40px 0 40px;
  text-align: left;

  display: flex;
  justify-content: space-between;
  opacity: 0.9;
  z-index: 20;

  button {
    display: flex;
    h4 {
      transition: color 0.2s ease-in-out, opacity 0.2s ease-in-out; 
      color: ${(props) => props.theme.text.header};
    }
  }
  button:hover {
    h4 {
      color: ${(props) => props.theme.text.color};
    }
    h4#light {
      opacity: 1;
    }
  }

  h4 {
    font-size: 0.9em;
    letter-spacing: 0.005em;
  }

  h4#light {
    opacity: 0.8;
    padding: 0 1px;
  }

  @media (min-width: 600px) {
    h4 {
      font-size: 0.9em;
      letter-spacing: 0.03em;
    }
  }
`;
