import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Header from './components/Header';
import Projects from './components/Projects';
import About from './components/About';

import { GlobalStyle } from './styles/global-styles';
import light from './styles/themes/light';
import dark from './styles/themes/dark';

const App = () => {
  const isMobile = () => window.innerWidth < 600;
  const [theme, setTheme] = useState('dark');
  const [mode, setMode] = useState(!isMobile() ? 'web-info-list' : 'mobile-list');

  return (
    <BrowserRouter>
      <Switch>
        <ThemeProvider theme={theme === 'light' ? light : dark}>
          <GlobalStyle />
          <Header mode={mode} setMode={setMode}/>
          <Route
            path="/"
            exact
            render={(props) => <Projects setMode={setMode} mode={mode} setTheme={setTheme}/>}
          />
          <Route
            path="/about"
            render={(props) => <About setTheme={setTheme} />}
          />
        </ThemeProvider>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
