import React, { useState, useEffect } from 'react';

import dataList from '../../data/playList.json';
import IconPlay from '../../assets/Icons/IconPlay';
import {
  ProjectSection,
  ProjectInfo,
  ProjectList,
  ProjectDetails,
  ProjectCredits,
  ProjectMedia,
  ProjectTitle,
  ProjectSubtitle,
  ListButton,
} from './styles';
import { ControllsSmall } from '../../styles/global-styles';

const Projects = ({ setMode, mode, setTheme = 'dark' }) => {
  const isMobile = () => window.innerWidth < 600;

  const [currentProjIndex, setCurrentProjIndex] = useState(isMobile() ? 0 : 3);
  const [tempProjindex, setTempProjIndex] = useState(currentProjIndex);
  const [titleId, setTitleId] = useState('');
  const [bgUrl, setBgUrl] = useState(null);
  const [displayEl, setDisplayEl] = useState({});
  const [listIsAnimated, setListIsAnimated] = useState(true);
  const playList = dataList;

  const pageState = () => {
    switch (mode) {
      case 'web-info-list':
        return {
          allInfo: true,
          player: false,
          info: true,
          list: true,
          playBtn: true,
        };
      case 'web-media':
      case 'mobile-media':
        return {
          allInfo: true,
          player: true,
          info: false,
          list: false,
          playBtn: false,
        };
      case 'mobile-list':
        return {
          allInfo: false,
          player: false,
          info: false,
          list: true,
          playBtn: false,
        };
      case 'mobile-info':
      default:
        return {
          allInfo: true,
          player: true,
          info: true,
          list: true,
          playBtn: false,
        };
    }
  };

  useEffect(() => {
    setDisplayEl(pageState());
    mode === 'web-media' ? setTitleId('media') : setTitleId('');
  }, [mode]);

  useEffect(() => {
    setBgUrl(
      require(`../../assets/imgs/${isMobile() ? 'mobile' : 'big'}/${
        playList[currentProjIndex].imgFile
      }`)
    );
    setTheme(playList[currentProjIndex].theme);
  }, [currentProjIndex]);

  useEffect(() => {
    if (listIsAnimated) {
      const randomizeBg = setInterval(() => {
        setCurrentProjIndex(
          Math.floor(Math.random() * (playList.length - 1) + 1)
        );
      }, 3500);
      return () => clearTimeout(randomizeBg);
    }
  }, [listIsAnimated]);

  const handlePlayBtn = () => {
    setListIsAnimated(false);
    if (!isMobile()) {
      setMode('web-media');
    }
  };

  const handleListItem = (e, projectId) => {
    if (e.type === 'click') {
      setListIsAnimated(false);
      setCurrentProjIndex(projectId);
      setTempProjIndex(projectId);

      if (isMobile()) {
        setMode('mobile-info');
      }
    } else {
      if (!isMobile()) {
        if (e.type === 'mouseenter') {
          setTempProjIndex(currentProjIndex);
          setCurrentProjIndex(projectId);
          setListIsAnimated(false);
        } else if (e.type === 'mouseleave') {
          setCurrentProjIndex(tempProjindex);
          setListIsAnimated(true);
        }
      }
    }
  };

  const updateProjectPlayer = (project) => {
    return (
      <ProjectMedia>
        <iframe
          title={project.shortTitle}
          src={project.videoUrl}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </ProjectMedia>
    );
  };

  const updateProjectList = (playList) => {
    return (
      <ProjectList id="project-list" className={`${currentProjIndex} ${mode}`}>
        {playList.map((project) => {
          return (
            <ListButton
              key={project.id}
              id={`listItem-${project.id}`}
              onClick={(e) => handleListItem(e, project.id)}
              // onMouseEnter={(e) => handleListItem(e, project.id)}
              // onMouseLeave={(e) => handleListItem(e, project.id)}
              position={`main${project.id + 1};`}
              styledItem={`#listItem-${currentProjIndex}`}
            >
              {project.shortTitle}
            </ListButton>
          );
        })}
      </ProjectList>
    );
  };

  const updateProjectInfo = () => {
    const title = isMobile()
      ? [playList[currentProjIndex].title]
      : playList[currentProjIndex].title.split('\n');

    return (
      <ProjectInfo id="project-info" className={mode}>
        <ProjectDetails id={titleId} className={mode}>
          {displayEl.info && (
            <ProjectTitle>
              {title.map((item, index) => (
                <h1 key={index}>{item}</h1>
              ))}
            </ProjectTitle>
          )}
          {displayEl.player && updateProjectPlayer(playList[currentProjIndex])}
          {displayEl.info && (
            <ProjectSubtitle>
              {playList[currentProjIndex].subtitle}
            </ProjectSubtitle>
          )}
          {displayEl.playBtn && (
            <ControllsSmall onClick={handlePlayBtn} type="button">
              <IconPlay />
            </ControllsSmall>
          )}
        </ProjectDetails>

        {displayEl.info && (
          <ProjectCredits className={currentProjIndex}>
            {playList[currentProjIndex].credits.map((credit, index) => (
              <div key={`credits-${index}`}>
                <p>
                  <span>{credit.title}</span>&nbsp;&nbsp;
                </p>
                <p>{credit.text}</p>
              </div>
            ))}
          </ProjectCredits>
        )}
      </ProjectInfo>
    );
  };

  return (
    <ProjectSection url={bgUrl} className={mode}>
      {displayEl.allInfo && updateProjectInfo()}
      {displayEl.list && updateProjectList(playList)}
    </ProjectSection>
  );
};

export default Projects;
