import styled from 'styled-components';

export const ProjectSection = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -10;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${(props) => props.url});
  }

  &.mobile-info::before,
  &.web-media::before {
    background-image: none;
    background-color: ${(props) => props.theme.backgroundProjects};
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    z-index: -2;
  }

  @media (min-width: 600px) {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

/* =========== PROJECT INFO =============*/
export const ProjectInfo = styled.section`
  padding: 100px 40px 20px 40px;
  height: 100%;

  @media (min-width: 600px) {
    display: grid;
    padding: 0;
    position: relative;
    top: 100px;
    height: calc(80% - 60px);
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 1fr;
  }
`;

/* =========== TITLES =============*/
export const ProjectDetails = styled.div`
  display: flex;
  flex-flow: column;

  button {
    z-index: 10;
    margin: auto;
    position: absolute;
    width: 100%;
    height: 100%;

    svg {
      fill: white;
      &:hover {
        fill: ${(props) => props.theme.text.light};
      }
    }
  }

  @media (min-width: 600px) {
    display: block;
    grid-column: 2 / span 2;
    grid-row: 2;
    transform: translateX(50px);

    button {
      padding-top: 20px;
      position: relative;
      width: auto;
      height: auto;
      transform: scale(0.7) translateX(-5px);

      svg {
        fill: ${(props) => props.theme.text.color};
      }
    }
  }

  @media (min-width: 600px) {
    &#media {
      grid-column: 2 / span 5;
      grid-row: 1 / span 3;
      justify-self: auto;
      align-self: auto;
    }
  }
  @media (max-width: 986px) {
    transform: translateX(0px);
  }
`;

/* =========== TITLE =============*/
export const ProjectTitle = styled.div`
  order: 1;
  padding-bottom: 5px;

  h1 {
    font-size: 14px;
    font-family: ${(props) => props.theme.text.font};
    text-transform: uppercase;
  }

  @media (min-width: 600px) {
    padding-bottom: 0;

    h1 {
      order: initial;
      font-size: 5vh;
      font-family: ${(props) => props.theme.text.fontTitle};
      line-height: 1.15em;
      text-transform: capitalize;
    }
  }
`;
export const ProjectSubtitle = styled.h3`
  order: 3;
  padding: 10px 0 0 0px;
  font-size: 12px;
  font-weight: 600;

  @media (min-width: 600px) {
    padding: 7px 0 0 0;
    order: initial;
    font-size: 0.8em;
    font-weight: 400;
  }
`;

/* =========== CREDITS =============*/
export const ProjectCredits = styled.div`
  line-height: 12px;
  padding: 10px 0 10px 0px;

  p {
    display: inline;
    font-size: 10px;
  }
  span {
    /* color: ${(props) => props.theme.text.dark}; */
    color: #bbb;
    font-style: italic;
  }

  @media (min-width: 600px) {
    width: calc(100% - 50px);
    padding: 0 0 0 0;
    line-height: normal;
    grid-column: 5 / span 2;
    grid-row: 2;
    max-height: 230px;
    overflow: hidden;
    transform: translateX(50px);

    p {
      font-size: 11px;
    }
  }
  @media (max-width: 986px) {
    transform: translateX(0px);
  }
`;
/* =========== PROJECT LIST =============*/
export const ProjectList = styled.section`
  padding: 100px 0 20px 40px;
  position: relative;
  bottom: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;

  &.mobile-info {
    order: 2;
    padding: 0 0 20px 40px;
    justify-content: flex-start;
  }

  @media (min-width: 600px) {
    position: absolute;
    padding: 0;
    bottom: 40px;
    height: 60px;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      'left main1 main4 main7 main10 main13 right'
      'left main2 main5 main8 main11 main14 right'
      'left main3 main6 main9 main12 main15 right';
    place-items: flex-start;
  }
`;

export const ListButton = styled.button`
  z-index: 20;
  display: block;
  line-height: 2.1em;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  transform: translateX(50px);
  color: ${(props) => props.theme.text.light};
  transition: color 0.2s ease-in-out, font-weight 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: ${(props) => props.theme.text.color};
    font-weight: 600;
  }

  &:focus {
    text-decoration: none;
  }

  &${(props) => props.styledItem} {
    text-decoration: line-through;
    font-weight: 600;
  }

  @media (min-width: 600px) {
    line-height: 1.6;
    grid-area: ${(props) => props.position};

    &:focus {
      text-decoration: line-through;
    }
  }
  @media (max-width: 986px) {
    transform: translateX(0px);
  }
`;

/* =========== PROJECT MEDIA =============*/
export const ProjectMedia = styled.div`
  height: 100%;
  width: 100%;
  order: 2;
  transform: translateX(-17px);

  @media (min-width: 600px) {
    padding-top: 100px;
    iframe {
      width: 100%;
      height: 100%;
      transform: translateY(-60px);
      height: 80%;
    }
  }
`;
