import React from 'react';
import { WebIcon } from '../../styles/global-styles';

export default function IconPlay() {
  return (
    <WebIcon
      width="12"
      height="12"
      xmlns="http://www.w3.org/2000/svg"
      
      fill-rule="evenodd"
      clip-rule="evenodd"
    >
      <path d="M0 0h12v12h-12z" />
    </WebIcon>
  );
}
