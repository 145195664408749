import React, { useEffect } from 'react';

// import info from '../../data/info.json';
import { AboutSection, AboutInfo, AboutSocial } from './styles';

const About = ({ setTheme }) => {
  useEffect(() => {
    setTheme('light');
  }, [setTheme]);

  return (
    <AboutSection>
      <AboutInfo>
        <p>
          We are a multidisciplinary studio based in Porto, Portugal. <br />
          We would love to hear from you.
          <a href="mailto:info@dinissottomayor.com" target="_black">
            <br/>info@dinissottomayor.com
          </a>
        </p>
        {/* <p>{info.about}</p> */}
      </AboutInfo>
      <AboutSocial>
        <a href="https://www.instagram.com/dinissottomayor/" target="_blank">
          instagram
        </a>
        <a href="https://vimeo.com/dinissottomayor/" target="_blank">
          vimeo
        </a>
      </AboutSocial>
    </AboutSection>
  );
};

export default About;
