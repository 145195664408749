import React from 'react';
import { WebIcon } from '../../styles/global-styles';

export default function IconPlay() {
  return (
    <WebIcon
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path d="M2 24v-24l20 12-20 12z" />
    </WebIcon>
  );
}
