import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import IconStmyr from '../../assets/Icons/IconStmyr';
import IconClose from '../../assets/Icons/IconClose';
import { HeaderSection } from './styles';
import { WebTitle } from '../../styles/global-styles';

const Header = ({ mode = 'web-info-list', setMode }) => {
  const history = useHistory();
  const [icon, setIcon] = useState(<IconStmyr />);

  const isMobile = () => window.innerWidth < 600;

  useEffect(() => {
    switch (mode) {
      case 'web-info-list':
      case 'mobile-list':
        setIcon(<IconStmyr />);
        break;
      case 'web-media':
      case 'about':
      case 'mobile-info':
      case 'mobile-media':
        setIcon(<IconClose />);
        break;
      default:
    }
  }, [mode]);

  const handleIconClick = () => {
    switch (mode) {
      case 'web-info-list':
      case 'mobile-list':
        history.push('/about');
        setMode('about');
        break;
      case 'web-media':
        setMode('web-info-list');
        break;
      case 'about':
        history.push('/');
        isMobile() ? setMode('mobile-list') : setMode('web-info-list');
        break;
      case 'mobile-info':
        setMode('mobile-list');
        break;
      case 'mobile-media':
        setMode('mobile-info');
        break;
      default:
        setMode('web-info-list');
        history.push('/');
    }
  };

  return (
    <HeaderSection>
      <WebTitle onClick={handleIconClick}>
        <h4>dinis</h4>
        <h4 id="light">sottomayor</h4>
        <h4>studio</h4>
      </WebTitle>
      <button onClick={handleIconClick}>{icon}</button>
    </HeaderSection>
  );
};

export default Header;
