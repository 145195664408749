/* font-family: 'Averia Serif Libre', 'Heebo', 'Lato', 'Roboto', sans-serif;  */
export default {
  title: 'light',
  text: {
    font: 'Karla, sans-serif',
    fontTitle: 'Averia Serif Libre, Karla, sans-serif',
    color: '#222',
    bright: '#222',
    light: '#222',
    dark: '#9a999a',
    header: '#222',
  },
  background: 'rgb(246, 246, 246)',//'#e6e6e6',
  backgroundTranslucid: 'rgba(246, 246, 246, 0.9)',
  backgroundProjects: 'rgb(246, 246, 246)',
  backgroundMax: '#fff'
};
