import styled from 'styled-components';

export const AboutSection = styled.div`
  padding: 140px 40px 40px 40px;
  width: 100%;
  margin: auto;

  &::before {
    background-color: ${(props) => props.theme.backgroundTranslucid};
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: -2;
    transform: translateX(-40px);
  }

  @media (min-width: 600px) {
    padding: 140px 40px 40px 40px;
  }
`;

export const AboutInfo = styled.section`
  width: 90%;
  margin: auto;

  p {
    font-size: 13px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.01em;
    word-spacing: 0.05em;
    font-size: 0.9em;
    line-height: 20px;

    font-size: 16px;
    letter-spacing: 0px;
    line-height: 20px;
  }
  a:hover {
      color: rgb(50, 50, 50);
    }

  @media (min-width: 600px) {
    width: 80%;

    p {
      font-size: 20px;
      line-height: 28px;
      /* text-align: initial; */
      letter-spacing: 0;
      word-spacing: 0;
    }
  }
`;

export const AboutSocial = styled.section`
  position: absolute;
  /* bottom: 40px; */
  display: grid;
  padding-top: 60px;
  /* padding: 0px; */
  width: 100%;
  transform: translateX(-40px);

  a {
    font-size: 20px;
    letter-spacing: 0px;
    line-height: 30px;
    text-decoration: none;
    text-align: center;

    &:hover {
      color: rgb(50, 50, 50);
    }
  }

  @media (min-width: 600px) {
    padding-top: 100px;
    bottom: auto;

    a {
      width: 100%;
      font-size: 50px;
      letter-spacing: -1px;
      line-height: 70px;
      font-size: 30px;
      line-height: 45px;
    }
  }
`;
