export default {
  title: 'dark',
  text: {
    font: 'Karla, sans-serif',
    fontTitle: 'Averia Serif Libre, Karla, sans-serif',
    color: 'white',
    bright: '#e6e6e6',
    light: '#e1e1e1',
    dark: '#9a999a',
    header: '#f1f1f1',
  },
  background: '#222',
  backgroundTranslucid: 'rgba(34,34,34, 0.9)',
  backgroundProjects: 'rgba(34,34,34, 0.9)',
  backgroundMax: '#111',
};
