/* font-family: 'Averia Serif Libre', 'Heebo', 'Lato', 'Roboto', sans-serif;  */
// font-family: Karla, Heebo, sans-serif;

import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    color: ${(props) => props.theme.text.bright}
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  html,
  body,
  #root {
    height: 100%;
  }

  body {
    -webkit-fonyt-smoothing: antialiased;
    background-color: ${(props) => props.theme.background}
  }

  body,
  input,
  button {
    font-family: ${(props) => props.theme.text.font};
  }

  h1 {
    font-size: 2em;
    font-weight: 600;
    letter-spacing: 0.4px;
    font-family: ${(props) => props.theme.text.fontTitle};
  }
  h3 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1em;
  }
  h4 {
    font-size: 1em;
  }
  p {
    font-size: 11px;
    line-height: 1.4;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.2s;
  }
  button:hover { color: ${(props) => props.theme.text.light}; }

  svg { transition: fill 0.2s; }

  svg:hover {
    fill: ${(props) => props.theme.text.light};
  }
`;

export const ControllsSmall = styled.button`
  transform: scale(0.5);
`;

export const ControllsBig = styled.button`
  transform: scale(1.2);
`;

export const WebTitle = styled.button`
  font-size: 1.2em;
  font-weight: 600;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.text.color};
`;

export const WebIcon = styled.svg`
  fill: ${(props) => props.theme.text.color};
`;

export const BackgroundImg = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -10;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${(props) => props.url});
`;
